
import { useI18n } from "vue-i18n/index";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditProductInputModal from "@/components/modals/referencials/product-inputs/EditProductInputModal.vue";
import SearchProductInputModal from "@/components/modals/referencials/product-inputs/SearchProductInputModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-product-inputs",
    props: {
        widgetClasses: String,
    },
    components: {
        EditProductInputModal,
        SearchProductInputModal,
    },
    setup() {
        const { t, te } = useI18n();

        let loading = ref(false);
        let activeVehicle = ref({
            id: null
        });
        let activeProductInput = ref<any>({});
        const productInputs = ref([]);
        const queryParamsDefault = {
            date: [],
            code: "",
            df_code: "",
            po_code: "",
            product_ids: [],
            description: "",
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const dateFormat = (value) => {
            return moment(value).format('DD-MM-YYYY');
        };

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            date : translate("productInputDateLabel"),
            code : translate("productInputCodeLabel"),
            df_code : translate("productInputDfCodeLabel"),
            po_code : translate("productInputPoCodeLabel"),
            product_ids : translate("productInputProductIdLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getProductInputs();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getProductInputs();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getProductInputs();
        };

        const create = () => {
            activeProductInput.value = {};
            Object.assign(activeProductInput.value, { vehicle_id: activeVehicle.value.id });

            const modal = new Modal(document.getElementById('kt_modal_edit_product_input'));
            modal.show();
        };

        const edit = (productInput) => {
            activeProductInput.value = productInput;
            const modal = new Modal(document.getElementById('kt_modal_edit_product_input'));
            modal.show();
        };

        const confirmRemove = (pdoruct_input_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("productInputConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(pdoruct_input_id);
                }
            })
        }

        const remove = (pdoruct_input_id: number) => {

            axios.delete(`${ApiRoutes.productInputs.delete}/${pdoruct_input_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });

                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchProductInputs = (params) => {

            for (let key in params) {

                if (
                    Array.isArray(params[key]) &&
                    params[key].length > 1 &&
                    moment(params[key][0], "YYYY-MM-DD", true).isValid() &&
                    moment(params[key][1], "YYYY-MM-DD", true).isValid()
                ) {
                    params[key][0] = dateFormat(params[key][0]);
                    params[key][1] = dateFormat(params[key][1]);
                } else {
                    queryParams.value[key] = params[key];
                }
            }

            refresh();
        };

        const removeQueryParam = (name: string): void => {
            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];

                refresh();
            }
        };

        const getProductInputs = async () => {
            loading.value = true;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.productInputs.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    productInputs.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            getProductInputs();

            setCurrentPageBreadcrumbs(translate("productInputHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("stockManagementHeading")
            ]);
        });

        return {
            translate,
            dateFormat,
            productInputs,
            pagination,
            activeProductInput,
            queryParams,
            queryParamsDefault,
            queryParamsLabels,
            searchProductInputs,
            removeQueryParam,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
